.attribute {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 5px;
}

.attribute-atom {
  display: inline-block;
  flex: "1 1 auto";
  align-items: center;
  justify-content: center;
  padding: 0px 15px 0px 20px;
  border-radius: 4px;
  font-size: 12px;
  min-width: 60px;
  height: 32px;
  line-height: 30px;
  cursor: pointer;
}

.attribute-atom > span {
  vertical-align: middle;
}

.attribute-type-color {
  color: #F5F5F5;
  background-color: #409eff;
}

.attribute-item-color {
  color: #409EFF;
  background-color: #ecf5ff;
}

.close-btn {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  padding: 2px 0px;
}

.item-close-btn:hover {
  color: #f5f5f5;
  background-color: #409eff;
}

.type-close-btn:hover {
  color: #f5f5f5;
  background-color: #66b1ff;
}

.mr-10 {
  margin-right: 10px;
}

.mr-5 {
  margin-right: 5px;
}
#logo {
  overflow: hidden;
  color: rgb(0, 0, 0);
  white-space: nowrap;
  text-decoration: none;
}

#logo img {
  margin-right: 6px;
  margin-top: -6px;
}
.bl-header {
  background-color: white;
  box-shadow: 0px 3px 6px rgba(91, 95, 152, 0.16);
}

.bl-padding {
  padding: 0 4%
}

.menuitem-padding {
  padding: 5px 4%;
}
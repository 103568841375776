.login-input-icon {
  color: rgba(0,0,0,0.25)
}

.login-forget-password {
  float: right;
  padding-right: 0;
}

.login-dialog-language {
  display: flex;
  justify-content: right;
  padding-bottom: 9px;
}
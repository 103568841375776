.description table td, .description table th {
  border: 1px solid #dcdfe6;
  padding: 4px 10px;
}

.description table {
  margin: 0 auto !important;
}

.description img {
  max-width: 400px;
}

.mt-10 {
  margin-top: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}